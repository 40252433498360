import React, { useState, useEffect } from 'react';
import { Api } from 'Api';
import { useParams, Link } from 'react-router-dom';
import Button from 'components/base/Button';
import DatePicker from 'components/base/DatePicker';
import ReactSelect from 'components/base/ReactSelect';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import { defaultBreadcrumbItems } from 'data/commonData';
import { Col, FloatingLabel, Form, Row, Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';

const WorkflowDetails = () => {
  const { workflowId } = useParams();

  const [buttonClicked, setButtonClicked] = useState(false);

  const [workflow, setWorkflow] = useState({
    workflow_id: 0,
    name: '',
    description: '',
    workflow_case_code: '',
    is_active: false
  });

  useEffect(() => {
    if (workflowId) {
      Api.get(`workflowapp/api/getWorkflow/${workflowId}/`)
        .then(response => {
          setWorkflow(response.data);
        })
        .catch(error => {
          // Check if the error response has data and a message
          console.log(error);
          if (error.response && error.response.status == 404) {
            toast.warning('Workflow not found', { autoClose: 1000 });
          } else if (error.response && error.response.data.message) {
            toast.warning(error.response.data.message, { autoClose: 1000 });
          } else {
            toast.warning('An unexpected error occurred.', { autoClose: 1000 });
          }
          setWorkflow({
            workflow_id: 0,
            name: '',
            description: '',
            workflow_case_code: '',
            is_active: false
          });
        });
    }
  }, [workflowId]);

  const handleChange = event => {
    const { name, value, type, checked } = event.target;
    setWorkflow(prevWorkflow => ({
      ...prevWorkflow,
      [name]: type === 'checkbox' ? checked : value
    }));
  };
  function timeout(delay) {
    return new Promise(res => setTimeout(res, delay));
  }
  const handleSubmit = async event => {
    setButtonClicked(true);
    await timeout(6000);
    event.preventDefault();
    try {
      const response = await Api.post(
        'workflowapp/api/saveWorkflow/', // API Endpoint, ensure it starts with a slash if needed
        { workflow: workflow }, // POST Parameters
        { headers: { 'Content-type': 'Application/json' } } // Request headers
      );

      // Check if a new workflow ID was returned and if the initial workflowId was not set
      if (!workflowId && response.data.workflow_id) {
        toast.success('Workflow saved successfully!', { autoClose: 1000 });
        window.location.href = `/crm-admin/workflow-details/${response.data.workflow_id}`;
      } else if (!workflowId && !response.data.workflow_id) {
        // Handle case where no new workflow ID is returned from the server
        toast.error('Workflow saved but no ID returned', { autoClose: 1000 });
      } else {
        // If workflowId was already set, we assume this is an update and handle accordingly
        toast.success('Workflow updated successfully!', { autoClose: 1000 });
      }
      // If there is a need to navigate after saving, use something like:
      // if(!workflowId && response.data.workflow_id) {
      //   window.location.href = `crm-admin/workflow-designer/${response.data.workflow_id}/`;
      // }
      console.log(response.data); // Log the response data for verification
    } catch (error) {
      toast.error('Cannot save workflow', { autoClose: 1000 });
      console.error('Failed', error); // Log the error to console
    } finally {
      setButtonClicked(false);
    }
  };

  return (
    <div>
      <PageBreadcrumb
        items={[
          { label: 'Workflow List', url: '/crm-admin/workflow-list/' },
          { label: 'Workflow Details', active: true }
        ]}
      />
      <h2 className="mb-4">
        {workflowId ? `Edit workflow` : `Create workflow`}
      </h2>
      <Row>
        <Col xs={12} xl={9}>
          <Row as="form" className="g-3 mb-6">
            <Form.Control
              type="hidden"
              placeholder="Workflowid"
              name="workflow_id"
              value={workflow.workflow_id}
            />
            <Col sm={6} md={8}>
              <FloatingLabel
                controlId="floatingInputGrid"
                label="Workflo Title"
              >
                <Form.Control
                  type="text"
                  placeholder="Workflow title"
                  name="name"
                  value={workflow.name}
                  onChange={handleChange}
                />
              </FloatingLabel>
            </Col>
            <Col sm={6} md={4}>
              <FloatingLabel controlId="floatingSelectTask" label="Description">
                <Form.Control
                  type="text"
                  placeholder="Description"
                  name="description"
                  value={workflow.description}
                  onChange={handleChange}
                />
              </FloatingLabel>
            </Col>
            <Col sm={6} md={4}>
              <FloatingLabel
                controlId="floatingSelectTask"
                label="Workflow Case Code"
              >
                <Form.Control
                  type="text"
                  placeholder="Case Code"
                  name="workflow_case_code"
                  value={workflow.workflow_case_code}
                  onChange={handleChange}
                />
              </FloatingLabel>
            </Col>
            <Col sm={6} md={4}>
              <Form.Check
                type="checkbox"
                id="is_active"
                label="Is Active?"
                name="is_active"
                checked={workflow.is_active}
                onChange={handleChange}
              />
            </Col>
            <Col xs={12} className="gy-6">
              <div className="d-flex justify-content-end gap-3">
                <Link
                  to="/crm-admin/workflow-list/"
                  className="px-5 phoenix-primary"
                >
                  Back to List
                </Link>
                <Button
                  variant="primary"
                  className="px-5 px-sm-15"
                  onClick={handleSubmit}
                  disabled={buttonClicked}
                >
                  {buttonClicked ? (
                    <span>
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                      Saving...
                    </span>
                  ) : workflowId ? (
                    'Save Workflow'
                  ) : (
                    'Create Workflow'
                  )}
                </Button>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default WorkflowDetails;
