import MainLayout from 'layouts/MainLayout';

import { RouteObject, createBrowserRouter } from 'react-router-dom';
import { Suspense, lazy } from 'react';
import App from 'App';

import Ecommerce from 'pages/dashboard/ecommerce';
import ProjectManagement from 'pages/dashboard/ProjectManagement';
import MainLayoutProvider from 'providers/MainLayoutProvider';
import HomePage from './home/HomePage';
import SignIn from './authentication/SignIn';
import SignOut from './authentication/SignOut';

import WorkFlow from './workflow/WorkflowDesigner';
//import LoginPage from './authentication/Login';
import PrivateRoute from './authentication/utils/PrivateRoute';
//import { AuthProvider } from './authentication/context/AuthContext';

import { ReactFlowProvider } from 'reactflow';
import Homepage from 'pages/apps/e-commerce/customer/Homepage';
import WorkflowList from './workflow/WorkflowList';
import WorkflowSteps from './workflow/WorkflowSteps';
import WorkflowDesigner from './workflow/WorkflowDesigner';
import WorkflowDetails from './workflow/WorkflowDetails';
import WfWithGroupNode from './test_workflow/WfWithGroupNode';

const FontAwesomeExample = lazy(
  () => import('pages/modules/components/FontAwesomeExample')
);

const FeatherIconsExample = lazy(
  () => import('pages/modules/components/FeatherIconsExample')
);

const UniconsExample = lazy(
  () => import('pages/modules/components/UniconsExample')
);

const routes: RouteObject[] = [
  {
    element: (
      // <AuthProvider>
      <App />
      // </AuthProvider>
    ),
    children: [
      {
        path: '/',
        element: (
          <MainLayoutProvider>
            <MainLayout />
          </MainLayoutProvider>
        ),
        children: [
          {
            path: 'auth',
            children: [
              {
                path: 'login/',
                element: <SignIn />
              },
              {
                path: 'logout/',
                element: <SignOut />
              }
            ]
          },
          {
            path: 'crm-admin',
            children: [
              {
                path: 'workflow-list/',
                element: <WorkflowList />
              },
              {
                path: 'workflow-details/:workflowId?/',
                element: <WorkflowDetails />
              },
              {
                path: 'workflow-steps/:workflowId/',
                element: <WorkflowSteps />
              },
              {
                path: 'workflow-designer/:workflowId?/',
                element: (
                    <ReactFlowProvider>
                      <WorkflowDesigner />
                    </ReactFlowProvider>
                )
              }
            ]
          },
          {
            path:"testwfg",
            element:<WfWithGroupNode />
          },
        ]
      }
    ]
  }
];

export const router = createBrowserRouter(routes);

export default routes;
