import React, {useContext, useRef} from 'react';
import { faKey, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import AuthSocialButtons from 'components/common/AuthSocialButtons';
import { Col, Form, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { AuthContext } from 'components/workflow-mgmt/authentication/context/AuthContext';

//import AuthContext , AuthContextType from 'components/workflow-mgmt/authentication/context/AuthContext';

const SignInForm = ({ layout }: { layout: 'simple' | 'card' | 'split' }) => {
  
  const authContext = useContext(AuthContext);
  if (!authContext) {
    throw new Error('useAuth must be used within an AuthProvider'); // Ensure context value exists
  }
  
  const { loginUser } = authContext; // Destructure loginUser from context value
  
  const usernameRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (usernameRef.current && passwordRef.current) {
      const formData = {
        username: usernameRef.current.value,
        password: passwordRef.current.value,
      };
    await loginUser(formData.username, formData.password); // Make sure to await the loginUser function
    }
  };

  return (
    <>
      <div className="text-center mb-7">
        <h3 className="text-1000">Sign In</h3>
        <p className="text-700">Get access to your account</p>
      </div>
      {/* <AuthSocialButtons title="Sign in" />
      <div className="position-relative">
        <hr className="bg-200 mt-5 mb-4" />
        <div className="divider-content-center">or use email</div>
      </div> */}
      <form onSubmit={handleSubmit}>
      <Form.Group className="mb-3 text-start">
        <Form.Label htmlFor="email">Email address/User Name</Form.Label>
        <div className="form-icon-container">
          <Form.Control
            id="username"
            //type="email"
            className="form-icon-input"
            ref={usernameRef}
            placeholder="name@example.com"
          />
          <FontAwesomeIcon icon={faUser} className="text-900 fs-9 form-icon" />
        </div>
      </Form.Group>
      <Form.Group className="mb-3 text-start">
        <Form.Label htmlFor="password">Password</Form.Label>
        <div className="form-icon-container">
          <Form.Control
            id="password"
            type="password"
            ref={passwordRef}
            className="form-icon-input"
            placeholder="Password"
          />
          <FontAwesomeIcon icon={faKey} className="text-900 fs-9 form-icon" />
        </div>
      </Form.Group>
      <Row className="flex-between-center mb-7">
        <Col xs="auto">
          <Form.Check type="checkbox" className="mb-0">
            <Form.Check.Input
              type="checkbox"
              name="remember-me"
              id="remember-me"
              defaultChecked
            />
            <Form.Check.Label htmlFor="remember-me" className="mb-0">
              Remember me
            </Form.Check.Label>
          </Form.Check>
        </Col>
        <Col xs="auto">
          <Link
            to={`/pages/authentication/${layout}/forgot-password`}
            className="fs-9 fw-semi-bold"
          >
            Forgot Password?
          </Link>
        </Col>
      </Row>
        <Button type="submit" variant="primary" className="w-100 mb-3">
        Sign In
      </Button>
      </form>
      <div className="text-center">
        <Link
          to={`/pages/authentication/${layout}/sign-up`}
          className="fs-9 fw-bold"
        >
          Create an account
        </Link>
      </div>
    </>
  );
};

export default SignInForm;
