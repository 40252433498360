import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
// import ProjectsTopSection from 'components/modules/workflow-management/ProjectsTopSection';
import WorkflowListTable, {
  workflowListTableColumns
} from 'components/workflow-mgmt/tables/WorkflowListTable';
import { defaultBreadcrumbItems } from 'data/commonData';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { Api } from 'Api';

const WorkflowList = () => {
  const [workflows, setWorkflows] = useState([]);

  useEffect(() => {
    const fetchWorkflows = async () => {
        try {
            const response = await Api.get('workflowapp/api/getAllWorkflows/'); // Corrected `APi` to `Api` to match the import
            setWorkflows(response.data);
        } catch (error) {
            // Handle error or show a message
            console.error('Failed to fetch workflows', error);
            setWorkflows([]); // Set to an empty array on error to ensure type consistency
        }
    };

    fetchWorkflows();
  }, []);

  const table = useAdvanceTable({
    data: workflows, // Now this is dynamically updated whenever workflows state changes
    columns: workflowListTableColumns,
    pageSize: 6,
    pagination: true,
    sortable: true
  });


  return (
    <div>
      {/* <PageBreadcrumb items={[{ label: 'Workflow List', url: '/crm-admin/workflow-list/' } ]} /> */}

      <AdvanceTableProvider {...table}>
        <div className="d-flex flex-wrap mb-4 gap-3 gap-sm-6 align-items-center">
          <h2 className="mb-0">
            <span className="me-3">Workflows</span>{' '}
            <span className="fw-normal text-700">({workflows.length})</span>
          </h2>
          <Link className="btn btn-primary px-5" to="/crm-admin/workflow-details">
            <FontAwesomeIcon icon={faPlus} className="me-2" />
            Add new workflow
          </Link>
        </div>
        {/* <ProjectsTopSection activeView="list" /> */}
        <WorkflowListTable />
      </AdvanceTableProvider>
    </div>
  );
};

export default WorkflowList;
