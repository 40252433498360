import React, { useState, useEffect } from 'react';
import { Modal, Form, Button, Row, Col } from 'react-bootstrap';
import ReactSelect from 'components/base/ReactSelect';
import { ToastContainer, toast } from 'react-toastify';
import { Api } from 'Api';
import { BsPlusCircle, BsDashCircle } from 'react-icons/bs';

const ModalDecision = props => {
  const {
    showModal,
    handleClose,
    selectedNode,
    updateNodeData,
    updateDecisionNode,
    nodes
  } = props;

  // Local state to manage form inputs
  const [stepName, setStepName] = useState('');
  const [actionName, setActionName] = useState('');
  const [workflowSteps,setWorkflowSteps] = useState();

  const initialCondition = {
    conditionField: null,
    conditionOperator: null,
    conditionValue: null,
    conditionTargetField: null,
    nextStep:null,
  };

  const [approvedConditions, setApprovedConditions] = useState([
    { conditionField: '', conditionOperator: '', conditionValue: 'on', conditionTargetField: '', isTargetFieldDropdown: true,nextStep:null }
  ]);
  const [rejectedConditions, setRejectedConditions] = useState([
    { conditionField: '', conditionOperator: '', conditionValue: 'off', conditionTargetField: '', isTargetFieldDropdown: true }
  ]);

  const operatorOptions = [
    { value: '==', label: 'equal to' },
    { value: '<>', label: 'not equal to' },
    { value: '>', label: 'greater than' },
    { value: '<', label: 'less than' },
    { value: '>=', label: 'greater than or equal to' },
    { value: '<=', label: 'less than or equal to' },
    { value: 'LIKE', label: 'like' },
    { value: 'NOT LIKE', label: 'not like' }
  ];

  const [formFieldList, setFormFieldList] = useState([]);
  const [isTargetFieldDropdown, setIsTargetFieldDropdown] = useState(true);

  useEffect(() => {
    getMasterLists();
    if (selectedNode) {
      setStepName(selectedNode.data.step_name);
      setActionName(selectedNode.data.action_name);
      setApprovedConditions(
        selectedNode.data.approved_conditions || [initialCondition]
      );
      setRejectedConditions(
        selectedNode.data.rejected_conditions || [initialCondition]
      );
    }
  }, [selectedNode]);

  const getMasterLists = async () => {
    try {
      const response = await Api.get(
        //`workflowapp/api/getFormFields/` + workflowId
        `workflowapp/api/getAllFormFields/`
      );
      if (response.status === 200) {
        setFormFieldList(response.data.formFieldList);
      } else {
        toast.error('Failed to fetch workflow data');
      }
    } catch (error) {
      console.error('Error fetching workflow data:', error);
      toast.error('Error fetching workflow data');
    }

    setWorkflowSteps(getWorkflowSteps());

  };

  const handleFormSubmit = event => {
    event.preventDefault();
    const updatedData = {
      label: stepName,
      step_name: stepName,
      action_name: actionName,
      approved_conditions: approvedConditions,
      //rejected_conditions: rejectedConditions
    };

    updateDecisionNode(selectedNode.id, updatedData);

    console.log(updatedData);

    handleClose();
  };

  const handleConditionChange = (index, type, field, value) => {
    if (type === 'approved') {
      const updatedConditions = approvedConditions.map((condition, i) => 
        i === index ? { ...condition, [field]: value } : condition
      );
      setApprovedConditions(updatedConditions);
    } else if (type === 'rejected') {
      const updatedConditions = rejectedConditions.map((condition, i) => 
        i === index ? { ...condition, [field]: value } : condition
      );
      setRejectedConditions(updatedConditions);
    }
  };

  const handleAddCondition = type => {
    const newCondition = {
      conditionField: '',
      conditionOperator: '',
      conditionValue: (type === 'approved' ? 'on':'off'),
      conditionTargetField: '',
      isTargetFieldDropdown: true
    };
    if (type === 'approved') {
      setApprovedConditions([...approvedConditions, newCondition]);
    } else {
      setRejectedConditions([...rejectedConditions, newCondition]);
    }
  };

  const handleRemoveCondition = (index, type) => {
    if (type === 'approved') {
      if (approvedConditions.length > 1) {
        const updatedConditions = approvedConditions.filter(
          (_, i) => i !== index
        );
        setApprovedConditions(updatedConditions);
      }
    } else {
      if (rejectedConditions.length > 1) {
        const updatedConditions = rejectedConditions.filter(
          (_, i) => i !== index
        );
        setRejectedConditions(updatedConditions);
      }
    }
  };
  const getWorkflowSteps = () => {
    return nodes.map((node) => ({
      value: node.id,
      label: node.data.label || node.data.step_name,
    }));
  };
  return (
    <Modal show={showModal} onHide={handleClose} size="xl">
      <Modal.Header closeButton>
        <Modal.Title>Edit Decision Node</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleFormSubmit}>
          <Row>
            <Form.Group className="mb-3 col-6" controlId="step_name">
              <Form.Label>Step Name</Form.Label>
              <Form.Control
                type="text"
                name="step_name"
                onChange={e => setStepName(e.target.value)}
                value={stepName}
              />
            </Form.Group>
          </Row>
          <h5>Approved Conditions</h5>
          {approvedConditions.map((condition, index) => (
                        <Row key={`approved-${index}`} className='align-items-end'>
              <Col md={3}>
                <Form.Group>
                  <Form.Label>Condition Field</Form.Label>
                  <ReactSelect
                    placeholder="Select Condition field"
                    options={formFieldList}
                    closeMenuOnSelect={true}
                    onChange={value => handleConditionChange(index, 'approved', 'conditionField', value)}
                    value={condition.conditionField}
                    isClearable={true}
                    isSearchable={true}
                    className="basic-multi-select"
                    classNamePrefix="select"
                  />
                </Form.Group>
              </Col>
              <Col md={3}>
                <Form.Group>
                  <Form.Label>Operator</Form.Label>
                  <ReactSelect
                    placeholder="Select Operator"
                    options={operatorOptions}
                    closeMenuOnSelect={true}
                    onChange={value => handleConditionChange(index, 'approved', 'conditionOperator', value)}
                    value={condition.conditionOperator}
                    isClearable={true}
                    isSearchable={true}
                    className="basic-multi-select"
                    classNamePrefix="select"
                  />
                </Form.Group>
              </Col>
              <Col md={2}>
                <Form.Group>
                  <Form.Label>Target Field</Form.Label>
                  <Form.Check
                    type="switch"
                    label="Use Dropdown"
                    checked={condition.isTargetFieldDropdown}
                    onChange={() => handleConditionChange(index, 'approved', 'isTargetFieldDropdown', !condition.isTargetFieldDropdown)}
                  />
                  {condition.isTargetFieldDropdown ? (
                    <ReactSelect
                      placeholder="Select Target Field"
                      options={formFieldList}
                      closeMenuOnSelect={true}
                      onChange={value => handleConditionChange(index, 'approved', 'conditionTargetField', value)}
                      value={condition.conditionTargetField}
                      isClearable={true}
                      isSearchable={true}
                      className="basic-multi-select"
                      classNamePrefix="select"
                    />
                  ) : (
                    <Form.Control
                      type="text"
                      placeholder="Enter Target Field"
                      value={condition.conditionTargetField}
                      onChange={e => handleConditionChange(index, 'approved', 'conditionTargetField', e.target.value)}
                    />
                  )}
                </Form.Group>
              </Col>
              <Col md={4}>
              <Form.Group className="mb-3 col-6" controlId="next_step">
              <Form.Label>Next Step</Form.Label>
                <ReactSelect
                  placeholder="Select Next Step"
                  options={workflowSteps}
                  closeMenuOnSelect={true}
                  onChange={value => handleConditionChange(index, 'approved', 'nextStep', value)}
                  value={condition.nextStep}
                  isClearable={true}
                  isSearchable={true}
                  className="basic-multi-select"
                  classNamePrefix="select"
                />
              </Form.Group>              
              </Col>
              <Col md={1}>
                {approvedConditions.length > 1 && (
                  <Button
                    variant="link"
                    className="text-danger"
                    onClick={() => handleRemoveCondition(index, 'approved')}
                  >
                    <BsDashCircle size={20} />
                  </Button>
                )}
                {index === approvedConditions.length - 1 && (
                  <Button
                    variant="link"
                    onClick={() => handleAddCondition('approved')}
                  >
                    <BsPlusCircle size={20} />
                  </Button>
                )}
              </Col>
            </Row>
          ))}

          <Button type="submit" className="mt-3">
            Save Changes
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default ModalDecision;
