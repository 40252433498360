import { useStore } from 'reactflow';
import "./NodeMenu.css";
import { FaEdit, FaTrashAlt, FaSave, FaTimes } from 'react-icons/fa';
export const NodeMenu = ({ node, onEdit, onDelete, onSave, onClose }) => {
  const transform = useStore((store) => store.transform);
console.log(transform);
  const style = {
    position: 'absolute',
    left: `${node.position.x * transform[2] + transform[0] + 320}px`, // Position horizontally beside the node
    top: `${node.position.y * transform[2] + transform[1]}px`, // Align vertically with the node
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'white',
    padding: '10px',
    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
    zIndex: 1000
  };

  return (
    <div style={style}>
      <button onClick={onEdit}><span style={{color:"blue"}} className="p-1"><FaEdit size={30} /></span></button>
      <button onClick={onDelete}><span style={{color:"red"}}><FaTrashAlt size={30} /></span></button>
      <button onClick={onSave}><span style={{color:"green"}}><FaSave size={30} /></span></button>
      <button onClick={onClose}><span style={{color:"gray"}}><FaTimes size={30} /></span></button>
    </div>
  );
};
