import React, {
  createContext,
  useState,
  useEffect,
  FC,
  ReactNode
} from 'react';
import { jwtDecode } from 'jwt-decode';
import { useNavigate } from 'react-router-dom';
import {toast } from "react-toastify";

export type User = {
  name: string;
  email: string;
};

const apiURL = process.env.REACT_APP_API_BASE_URL;

interface AuthContextProps {
  user: User;
  authTokens: any;
  loginUser: (email: string, password: string) => Promise<void>;
  logoutUser: () => void;
}

interface AuthProviderProps {
  children: ReactNode; // Ensure children prop is of type ReactNode
}

export const AuthContext = createContext<AuthContextProps | null>(null);

export const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
  const [user, setUser] = useState<any>(() =>
    localStorage.getItem('authTokens')
      ? jwtDecode(localStorage.getItem('authTokens')!)
      : null
  );
  const [authTokens, setAuthTokens] = useState<any>(() =>
    localStorage.getItem('authTokens')
      ? JSON.parse(localStorage.getItem('authTokens')!)
      : null
  );
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  const loginUser = async (username: string, password: string) => {
    const response = await fetch(apiURL + 'authapp/api/token/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ username, password })
    });

    const data = await response.json();

    if (data && response.status === 200) {
      localStorage.setItem('authTokens', JSON.stringify(data));
      setAuthTokens(data);
      setUser(jwtDecode(data.access));
      navigate('/');
    } else {
      toast.warning('Something went wrong while logging in the user!',{autoClose:1000});
    }
  };

  const logoutUser = () => {
    localStorage.removeItem('authTokens');
    setAuthTokens(null);
    setUser(null);
    navigate('/auth/login');
  };

  const updateToken = async () => {
    const response = await fetch(
      apiURL + 'authapp/api/token/refresh/',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ refresh: authTokens?.refresh })
      }
    );

    const data = await response.json();
    if (response.status === 200) {
      setAuthTokens(data);
      setUser(jwtDecode(data.access));
      localStorage.setItem('authTokens', JSON.stringify(data));
    } else {
      logoutUser();
    }

    if (loading) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (loading) {
      updateToken();
    }

    const REFRESH_INTERVAL = 1000 * 60 * 60 * 4; // 4 Hours
    const interval = setInterval(() => {
      if (authTokens) {
        updateToken();
      }
    }, REFRESH_INTERVAL);
    return () => clearInterval(interval);
  }, [authTokens, loading]);

  const contextData: AuthContextProps = {
    user,
    authTokens,
    loginUser,
    logoutUser
  };

  return (
    <>
    <AuthContext.Provider value={contextData}>{children}</AuthContext.Provider>
    </>
  );
};
