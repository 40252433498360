import React, { useState, useCallback, useEffect, useRef } from 'react';
import ReactFlow, {
  addEdge,
  MiniMap,
  Controls,
  Background,
  useNodesState,
  useEdgesState,
  updateEdge
} from 'reactflow';
import { useParams } from 'react-router-dom';
import { Container, Row, Col, Button, Modal, Form } from 'react-bootstrap';
import { Api } from 'Api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import { nodes as initialNodes, edges as initialEdges } from './initial-elements';
import CustomNode from './CustomNode';
import './WorkflowDesigner.css';
import 'reactflow/dist/style.css';
//import './overview.css';
import ReactSelect from 'components/base/ReactSelect';
import { NodeMenu } from './NodeMenu';
import ModalProcess from './ModalProcess';
import ModalDecision from './ModalDecision';

const nodeTypes = {
  CustomNode: CustomNode
};

const minimapStyle = {
  height: 120
};

const initialNodes = [
  {
    id: '1',
    type: 'input',
    data: {
      label: 'Initiate Case'
    },
    position: { x: 0, y: 0 }
  }
];

const initialEdges = [
  //{ id: 'e1-2', source: '1', target: '2', label: 'this is an edge label' },
  //{ id: 'e1-3', source: '1', target: '3', animated: true },
];

const onInit = reactFlowInstance =>
  {const temp = reactFlowInstance;}
  //console.log('flow loaded:', reactFlowInstance);

const WorkflowDesigner = () => {
  const { workflowId } = useParams();

  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);

  const [showModal, setShowModal] = useState(false); //to display modal
  const [drawerOpen, setDrawerOpen] = useState(false); //to display menu drawer
  const [selectedNode, setSelectedNode] = useState(null);
  const [workflowDetails, setWorkflowDetails] = useState();
  const [currentNode, setCurrentNode] = useState(null);
  const [showModalProcess, setShowModalProcess] = useState(false);
  const [showModalDecision, setShowModalDecision] = useState(false);

  const [actionName, setActionName] = useState();
  const [slaId, setSlaId] = useState();
  const [formDefinitionId, setFormDefinitionId] = useState();
  const [preAssignedUserId, setPreAssignedUserId] = useState();
  const [preAssignedUserTypeId, setPreAssignedUserTypeId] = useState();

  const [slaMasterList, setSLAMasterList] = useState([]);
  const [formMasterList, setFormMasterList] = useState([]);
  const [WfTaskUsersMasterList, setWfTaskUsersMasterList] = useState([]);
  const [WfTaskUserTypesMasterList, setWfTaskUserTypesMasterList] = useState([]);

  const [loadData,setLoadData] = useState(false);

  //const [workflowId, setWorkflowId] = useState(workflowIdParam);
  const [actionList, setActionList] = useState([
    { value: 'submit_form', label: 'submit_form' },
    { value: 'process_reimbursement', label: 'process_reimbursement' },
    { value: 'send_notification', label: 'send_notification' },
    { value: 'wfa_send_email', label: 'wfa_send_email' }
  ]);
  const getWorkflowData = async () => {
    try {
      const response = await Api.get(
        `workflowapp/api/getWorkflow/${workflowId}/`
      );
      if (response.status === 200) {
        response.data.workflow_json
          ? setNodes(response.data.workflow_json.nodes)
          : setNodes(initialNodes);
        response.data.workflow_json
          ? setEdges(response.data.workflow_json.edges)
          : setEdges(initialEdges);
        setWorkflowDetails(response.data);
      } else {
        toast.error('Failed to fetch workflow data');
      }
    } catch (error) {
      console.error('Error fetching workflow data:', error);
      toast.error('Error fetching workflow data');
    }
  };

  const getMasterLists = async () => {
    try {
      const response = await Api.get(
        `workflowapp/api/getWfDesignerMasterLists/`
      );
      if (response.status === 200) {
        setSLAMasterList(response.data.slas);
        setFormMasterList(response.data.forms);
        setWfTaskUsersMasterList(response.data.task_users);
        setWfTaskUserTypesMasterList(response.data.task_user_types);
      } else {
        toast.error('Failed to fetch workflow data');
      }
    } catch (error) {
      console.error('Error fetching workflow data:', error);
      toast.error('Error fetching workflow data');
    }
  };

  useEffect(() => {
    getWorkflowData();
    getMasterLists();
  }, [workflowId]);

  useEffect(() => {
    if(loadData){
      getWorkflowData();
      getMasterLists();
      setLoadData(false);
    }
  }, [loadData]);

  useEffect(() => {
    //when the selectedNode is changing, update the value so that correct values are displayed in the modal form
    if (selectedNode) {
      setActionName(selectedNode.data.action_name);
      setSlaId(selectedNode.data.sla_id);
      setFormDefinitionId(selectedNode.data.form_definition_id);
      setPreAssignedUserId(selectedNode.data.pre_assigned_user_id);
      setPreAssignedUserTypeId(selectedNode.data.pre_assigned_user_type_id);
    }
  }, [selectedNode]); // Dependency array to ensure updates if selectedNode changes

  const handleSubmit = event => {
    event.preventDefault();

    // var workflow = [
    //   {
    //     workflow_id: null,
    //     name: 'first workflow',
    //     description: 'first workflow',
    //     workflow_case_code: 'wdf232',
    //     is_active: true
    //   }
    // ];

    const saveWorkflow = async () => {
      try {
        const response = await Api.post(
          '/workflowapp/api/saveWorkflowDesignerSteps/', // API Endpoint
          { workflow: workflowDetails, nodes, edges }, //POST Parameters
          { headers: { 'Content-type': 'Application/json' } } //request headers (Authorization etc)
        );
        //setBlogList(response.data.blogList);
        setLoadData(true);
        toast.success('Workflow Saved', { autoClose: 1000 });
        if (!workflowId && response.data.workflow_id)
          window.location.href =
            'crm-admin/workflow-designer/' + response.data.workflow_id + '/';
        console.log(response.data);
      } catch (error) {
        toast.error('Workflow not saved, please try later', {
          autoClose: 1000
        });
        console.error('Failed', error);
      }
    };

    saveWorkflow();
  }; //

  // - Before chaging for workflow-group - start
  // const onConnect = useCallback(
  //   params => setEdges(eds => addEdge(params, eds)),
  //   []
  // );
  // - Before changing for workflow-group - end
  
  const edgeUpdateSuccessful = useRef(true);

  const onConnect = useCallback(
    (params) => {
      setEdges((eds) => {
        const updatedEdges = addEdge(params, eds);
        const nodeOutgoingConnections = {};

        updatedEdges.forEach((edge) => {
          nodeOutgoingConnections[edge.source] = (nodeOutgoingConnections[edge.source] || 0) + 1;
        });
        
        console.log(nodeOutgoingConnections);

        setNodes((nds) => 
          nds.map((node) => {
            if (nodeOutgoingConnections[node.id] > 1) {
              return {
                ...node,
                'workflow-group': true,
                data: {
                  ...node.data,
                  'workflow-group': true,
                },
              };
            }
            return node;
          })
        );

        const updatedNodes = updatedEdges.reduce((acc, edge) => {
          const targetNode = nodes.find(node => node.id === edge.target);
          console.log(targetNode);
          if (nodeOutgoingConnections[edge.source] > 1 && targetNode) {
            acc.push({
              ...targetNode,
              data: {
                ...targetNode.data,
                child_node: true,
                parent_node_id: edge.source,
              },
            });
          }
          return acc;
        }, []);
        


        setNodes(nds =>
          nds.map(node =>
            updatedNodes.find(updatedNode => updatedNode.id === node.id) || node
          )
        );
        console.log("updated nodes: "  + updatedNodes);
        return updatedEdges;
      });
    },
    [setNodes, setEdges]
  );
  
  const onEdgesDelete = useCallback(
    (deletedEdges) => {
      setEdges((eds) => {
        const remainingEdges = eds.filter(
          (edge) => !deletedEdges.some((deletedEdge) => deletedEdge.id === edge.id)
        );
        const nodeOutgoingConnections = {};
  
        remainingEdges.forEach((edge) => {
          nodeOutgoingConnections[edge.source] = (nodeOutgoingConnections[edge.source] || 0) + 1;
        });
  
        setNodes((nds) =>
          nds.map((node) => {
            if (nodeOutgoingConnections[node.id] > 1) {
              return {
                ...node,
                data: {
                  ...node.data,
                  'workflow-group': true,
                },
              };
            } else if (node.data['workflow-group']) {
              const updatedData = { ...node.data };
              delete updatedData['workflow-group'];
              return {
                ...node,
                data: updatedData,
              };
            }
            return node;
          })
        );
  
        return remainingEdges;
      });
    },
    [setNodes, setEdges]
  );

  const onEdgeUpdateStart = useCallback(() => {
    edgeUpdateSuccessful.current = false;
  }, []);

  const onEdgeUpdate = useCallback(
    (oldEdge, newConnection) => {
      edgeUpdateSuccessful.current = true;
      setEdges((els) => updateEdge(oldEdge, newConnection, els));
    },
    []
  );

  const onEdgeUpdateEnd = useCallback(
    (_, edge) => {
      if (!edgeUpdateSuccessful.current) {
        setEdges((eds) => eds.filter((e) => e.id !== edge.id));
      }

      edgeUpdateSuccessful.current = true;

      const nodeOutgoingConnections = {};
      edges.forEach((edge) => {
        nodeOutgoingConnections[edge.source] = (nodeOutgoingConnections[edge.source] || 0) + 1;
      });

      setNodes((nds) =>
        nds.map((node) => {
          if (nodeOutgoingConnections[node.id] > 1) {
            return {
              ...node,
              data: {
                ...node.data,
                'workflow-group': true,
              },
            };
          } else if (node.data['workflow-group']) {
            const updatedData = { ...node.data };
            delete updatedData['workflow-group'];
            return {
              ...node,
              data: updatedData,
            };
          }
          return node;
        })
      );
    },
    [edges, setNodes]
  );



  // we are using a bit of a shortcut here to adjust the edge type
  // this could also be done with a custom edge for example
  const edgesWithUpdatedTypes = edges.map(edge => {
    if (edge.sourceHandle) {
      const edgeType = nodes.find(node => node.type === 'custom').data.selects[
        edge.sourceHandle
      ];
      edge.type = edgeType;
    }

    return edge;
  });

  //This is for displaying popup - Start
  //   const handleNodeClick = (event, node) => {
  //     setCurrentNode(node);
  //     setShowModal(true);
  //   };
  //This is for displaying popup - End

  //  This is for displaying menu drawer - Start
  const handleNodeClick = (event, node) => {
    console.log(node);
    setSelectedNode(node);
    setDrawerOpen(true);
  };
  //This is for displaying popup - End

  const handleModalClose = () => {setShowModalDecision(false);setShowModalProcess(false);}

  const updateProcessNode = ( nodeId, updatedData) => {
    
    const updatedNode = {
      ...selectedNode,
      data: {
        ...selectedNode.data,
        label: updatedData.step_name,
        step_name: updatedData.step_name,
        action_name: updatedData.action_name,
        sla_id: updatedData.sla_id,
        form_definition_id: updatedData.form_definition_id,
        pre_assigned_user_id: updatedData.pre_assigned_user_id,
        pre_assigned_user_type_id: updatedData.pre_assigned_user_type_id
      }
    };
    setNodes(nds => nds.map(n => (n.id === selectedNode.id ? updatedNode : n)));
    setShowModalProcess(false);
  };

  // const updateDecisionNode = (nodeId, updatedData) => {
  //   const newNodes = nodes.map(node => {
  //     if (node.id === nodeId) {
  //       return { ...node, data: { ...node.data, ...updatedData } };
  //     }
  //     return node;
  //   });
  //   setNodes(newNodes);
  //   setShowModalDecision(false);
  // };  
  const updateDecisionNode = ( nodeId, updatedData) => {
    
    const updatedNode = {
      ...selectedNode,
      data: {
        ...selectedNode.data,
        // label: updatedData.step_name,
        // step_name: updatedData.step_name,
        // action_name: updatedData.action_name,
        // condition_field: updatedData.condition_field,
        // condition_operator: updatedData.condition_operator,
        // condition_value: updatedData.condition_value,
        // condition_target_field: updatedData.condition_target_field,        
        label: updatedData.step_name,
        step_name: updatedData.step_name,
        action_name:  updatedData.action_name,
        approved_conditions: updatedData.approved_conditions,
        //rejected_conditions: updatedData.rejected_conditions        
      }
    };
    setNodes(nds => nds.map(n => (n.id === selectedNode.id ? updatedNode : n)));
    setShowModalProcess(false);
  };



  // const handleFormSubmit = event => {
  //   event.preventDefault();
  //   const formData = new FormData(event.target);

  //   const updatedNode = {
  //     ...selectedNode,
  //     data: {
  //       ...selectedNode.data,
  //       label: formData.get('step_name'),
  //       step_name: formData.get('step_name'),
  //       action_name: actionName,
  //       sla_id: slaId,
  //       form_definition_id: formDefinitionId,
  //       pre_assigned_user_id: preAssignedUserId,
  //       pre_assigned_user_type_id: preAssignedUserTypeId
  //     }
  //   };
  //   setNodes(nds => nds.map(n => (n.id === selectedNode.id ? updatedNode : n)));
  //   handleModalClose();
  // };

  const addNode = () => {
    const newNode = {
      id: (nodes.length + 1).toString(), // Generate unique ID for new node
      type: 'default', // Set the type of your new node
      data: { label: `New Node ${nodes.length + 1}` }, // Provide any data you want to associate with the new node
      position: { x: 0, y: 0 } // Set the initial position of the new node
    };

    setNodes(prevNodes => [...prevNodes, newNode]); // Add the new node to the nodes array
  };

  const onDrop = event => {
    event.preventDefault();
    const data = JSON.parse(
      event.dataTransfer.getData('application/reactflow')
    );
    const newNode = {
      ...data,
      id: `${data.type}-${new Date().getTime()}`,
      position: { x: -20, y: -20 }
    };
    setNodes(prevNodes => [...prevNodes, newNode]);
  };

  const handleDrag = event => {
    setDrawerOpen(false); //hide the drawer menu when the node is being dragged
  };

  const onDragOver = event => {
    event.preventDefault();
  };

  /***************Drawer Functions start */
  const handleCloseDrawer = () => setDrawerOpen(false);

  const handleEdit = () => {
    // Define edit logic or open another modal for detailed edit
    //setShowModal(true);

    
    if (selectedNode.node_type === 'process') {
      setShowModalProcess(true);
    } else if (selectedNode.node_type === 'decision') {
      setShowModalDecision(true);
    }

  };

  const handleDelete = () => {
    const res = window.confirm('Current node will be deleted, are you sure?');

    if (res) {
      setNodes(nds => nds.filter(n => n.id !== selectedNode.id));
      handleCloseDrawer();
    }
  };

  const handleSave = () => {
    // Save logic here
    toast.success('Data Saved');
  };

  const handleClose = () => {

    handleCloseDrawer();
  };

  /***************Drawer Functions end */

  return (
    <>
      <div className="container mx-4">
        <div className="row">
          <div className="h2 text-center">{workflowDetails?.name}</div>
          <div className="col-10">
            <div
              //   style={{ height: '800px', width: '1050px' }}
              className="react-flow-wrapper"
              onDrop={onDrop}
              onDragOver={onDragOver}
            >
              <ReactFlow
                nodes={nodes}
                edges={edgesWithUpdatedTypes}
                onNodesChange={onNodesChange}
                onEdgesChange={onEdgesChange}
                onEdgesDelete={onEdgesDelete}
                onEdgeUpdateStart={onEdgeUpdateStart}
                onEdgeUpdate={onEdgeUpdate}
                onEdgeUpdateEnd={onEdgeUpdateEnd}
                onConnect={onConnect}
                onInit={onInit}
                fitView
                onNodeClick={handleNodeClick}
                onNodeDrag={handleDrag}
                attributionPosition="top-right"
                nodeTypes={nodeTypes}
              >
                <MiniMap style={minimapStyle} zoomable pannable />
                <Controls />
                <Background color="#aaa" gap={16} />
              </ReactFlow>
            </div>
          </div>
          <div className="col-2">
            <div className="row my-3">
              <div
                className="badge bg-info"
                draggable
                onDragStart={event => {
                  event.dataTransfer.setData(
                    'application/reactflow',
                    JSON.stringify({
                      type: 'output',
                      node_type: 'initiate',
                      data: { label: 'Initiate case' }
                    })
                  );
                }}
              >
                Initiate case
              </div>
            </div>
            <div className="row my-3">
              <div
                className="badge bg-secondary"
                draggable
                onDragStart={event => {
                  event.dataTransfer.setData(
                    'application/reactflow',
                    JSON.stringify({
                      type: 'default',
                      node_type: 'process',
                      data: {
                        label: 'Process step',
                        action_name: '',
                        step_name: '',
                        step_type: '',
                        sla_id: '',
                        form_definition_id: '',
                        pre_assigned_user_id: '',
                        pre_assigned_user_type_id: ''
                      }
                    })
                  );
                }}
              >
                Process step
              </div>
            </div>
            <div className="row my-3">
              <div
                className="badge bg-secondary"
                draggable
                onDragStart={event => {
                  event.dataTransfer.setData(
                    'application/reactflow',
                    JSON.stringify({
                      type: 'default',
                      node_type: 'decision',
                      data: {
                        label: 'Decision step'
                      }
                    })
                  );
                }}
              >
                Decision step
              </div>
            </div>
            <div className="row my-3">
              <span
                className="badge bg-success"
                draggable
                onDragStart={event => {
                  event.dataTransfer.setData(
                    'application/reactflow',
                    JSON.stringify({
                      type: 'output',
                      node_type: 'finish',
                      data: { label: 'Finish workflow' }
                    })
                  );
                }}
              >
                Finish workflow
              </span>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <Button variant="primary" onClick={handleSubmit}>
              Save Workflow
            </Button>
          </div>
        </div>

      {/* Modal for Process Nodes */}
      <ModalProcess
        showModal={showModalProcess}
        handleClose={() => setShowModalProcess(false)}
        selectedNode={selectedNode}
        updateNodeData={updateProcessNode}
        actionList={actionList}
        slaMasterList={slaMasterList}
        formMasterList={formMasterList}
        WfTaskUsersMasterList={WfTaskUsersMasterList}
        WfTaskUserTypesMasterList={WfTaskUserTypesMasterList}   
        updateProcessNode={updateProcessNode}     
      />

      {/* Modal for Decision Nodes */}
      <ModalDecision
        showModal={showModalDecision}
        handleClose={() => setShowModalDecision(false)}
        selectedNode={selectedNode}
        updateDecisionNode={updateDecisionNode}
        workflowId={workflowId}
        nodes={nodes}
      />

        {/* Drawer for node actions */}
        {drawerOpen && (
          <NodeMenu
            node={selectedNode}
            onEdit={handleEdit}
            onDelete={handleDelete}
            onSave={handleSave}
            onClose={handleClose}
          />
        )}
      </div>
      <ToastContainer />
    </>
  );
};

export default WorkflowDesigner;
