import axios from "axios";

export const Api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

export const AuthApi = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

// Add trailing slash interceptor for Api
Api.interceptors.request.use(
  config => {
    if (!config.url.endsWith('/')) {
      config.url += '/';
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

// Add trailing slash and authorization token interceptor for AuthApi
AuthApi.interceptors.request.use(
  config => {
    const token = localStorage.getItem('accessToken');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    if (!config.url.endsWith('/')) {
      config.url += '/';
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);
