import React, { memo } from 'react';
import { Handle, Position } from 'reactflow';

export default memo(({ data, isConnectable }) => {
  return (
    <>
      <div className="badge bg-secondary">
        <div>
          Custom Color Picker Node: <strong>{data.color}</strong>
        </div>
        <input className="nodrag" type="color" onChange={data.onChange} defaultValue={data.color} />
        <Handle
          type="target"
          position={Position.Top}
          style={{ background: '#555', left: 85 }}
          onConnect={(params) => console.log('handle onConnect', params)}
          isConnectable={isConnectable}
        />
      </div>
      <div>
        <Handle
          type="source"
          position={Position.Bottom}
          id="a"
          style={{ left: 40, background: '#555' }}
          isConnectable={isConnectable}
        />
        <Handle
          type="source"
          position={Position.Bottom}
          id="b"
          style={{ left: 140, background: '#555' }}
          isConnectable={isConnectable}
        />
      </div>
    </>
  );
});
