import React, { useState, useEffect } from 'react';
import { Modal, Form, Row, Button } from 'react-bootstrap';
import ReactSelect from 'components/base/ReactSelect';
import { ToastContainer, toast } from 'react-toastify';

const ModalProcess = props => {
  const {
    showModal,
    handleClose,
    selectedNode,
    actionList,
    slaMasterList,
    formMasterList,
    WfTaskUsersMasterList,
    WfTaskUserTypesMasterList,
    updateProcessNode
  } = props;
  const [stepName, setStepName] = useState();
  const [actionName, setActionName] = useState();
  const [slaId, setSlaId] = useState();
  const [formDefinitionId, setFormDefinitionId] = useState();
  const [preAssignedUserId, setPreAssignedUserId] = useState();
  const [preAssignedUserTypeId, setPreAssignedUserTypeId] = useState();

  useEffect(() => {
    if (selectedNode) {
      setStepName(selectedNode.data.step_name);
      setActionName(selectedNode.data.action_name);
      setSlaId(selectedNode.data.sla_id);
      setFormDefinitionId(selectedNode.data.form_definition_id);
      setPreAssignedUserId(selectedNode.data.pre_assigned_user_id);
      setPreAssignedUserTypeId(selectedNode.data.pre_assigned_user_type_id);
    }
  }, [selectedNode]);

  const handleFormSubmit = event => {
    event.preventDefault();
    const updatedData = {
      label: stepName,
      step_name: stepName,
      action_name: actionName,
      sla_id: slaId,
      form_definition_id: formDefinitionId,
      pre_assigned_user_id: preAssignedUserId,
      pre_assigned_user_type_id: preAssignedUserTypeId
    };

    updateProcessNode(selectedNode.id, updatedData);
  };

  //   const handleFormSubmit = event => {
  //     event.preventDefault();
  //     updateNode({
  //       actionName,
  //       slaId,
  //       formDefinitionId,
  //       preAssignedUserId,
  //       preAssignedUserTypeId
  //     });
  //     handleModalClose();
  //   };

  return (
    <>
      <Modal show={showModal} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Edit Node - {selectedNode?.node_type} </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleFormSubmit}>
            <Row>
              <Form.Group className="mb-3 col-6" controlId="step_name">
                <Form.Label>Step Name</Form.Label>
                <Form.Control
                  type="text"
                  name="step_name"
                  defaultValue={selectedNode?.data.step_name}
                  onChange={e => setStepName(e.target.value)}
                  value={stepName}
                />
              </Form.Group>
              <Form.Group className="mb-3 col-6" controlId="action_name">
                <Form.Label>Action Name</Form.Label>
                <ReactSelect
                  placeholder="Select Action"
                  options={actionList}
                  closeMenuOnSelect={true}
                  onChange={value => setActionName(value)}
                  value={actionName}
                />
              </Form.Group>
            </Row>
            <Row>
              <Form.Group className="mb-3 col-6" controlId="sla_id">
                <Form.Label>SLA</Form.Label>
                <ReactSelect
                  placeholder="Select SLA"
                  options={slaMasterList}
                  closeMenuOnSelect={true}
                  onChange={value => setSlaId(value)}
                  value={slaId}
                />
              </Form.Group>
              <Form.Group className="mb-3 col-6" controlId="form_definition_id">
                <Form.Label>Form</Form.Label>
                <ReactSelect
                  placeholder="Select Form Definition"
                  options={formMasterList}
                  closeMenuOnSelect={true}
                  onChange={value => setFormDefinitionId(value)}
                  value={formDefinitionId}
                />
              </Form.Group>
            </Row>
            <Row>
              <Form.Group
                className="mb-3 col-6"
                controlId="pre_assigned_user_id"
              >
                <Form.Label>Assigned User</Form.Label>
                <ReactSelect
                  placeholder="Select User"
                  options={WfTaskUsersMasterList}
                  closeMenuOnSelect={true}
                  onChange={value => setPreAssignedUserId(value)}
                  value={preAssignedUserId}
                />
              </Form.Group>
              <Form.Group
                className="mb-3 col-6"
                controlId="pre_assigned_user_type_id"
              >
                <Form.Label>Assigned Group</Form.Label>
                <ReactSelect
                  placeholder="Select User Group"
                  options={WfTaskUserTypesMasterList}
                  closeMenuOnSelect={true}
                  onChange={value => setPreAssignedUserTypeId(value)}
                  value={preAssignedUserTypeId}
                />
              </Form.Group>
            </Row>
            {/* <Form.Group className="mb-3" controlId="form_definition_id">
                <Form.Label>Form Definition ID</Form.Label>
                <Form.Control
                  type="text"
                  name="form_definition_id"
                  defaultValue={selectedNode?.data.form_definition_id}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="pre_assigned_user_id">
                <Form.Label>Pre-assigned User</Form.Label>
                <Form.Control
                  type="text"
                  name="pre_assigned_user_id"
                  defaultValue={selectedNode?.data.pre_assigned_user_id}
                />
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="pre_assigned_user_type_id"
              >
                <Form.Label>Pre-assigned User Type</Form.Label>
                <Form.Control
                  type="text"
                  name="pre_assigned_user_type_id"
                  defaultValue={selectedNode?.data.pre_assigned_user_type_id}
                />
              </Form.Group> */}
            <Button variant="primary" type="submit">
              Save Changes
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ModalProcess;
